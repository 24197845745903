.section-image {
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;

  .title,
  .card-plain .card-title {
    color: $white-color;
  }

  .nav-pills .nav-link {
    background-color: $white-color;

    &:hover,
    &:focus {
      background-color: $white-color;
    }
  }

  .info-title,
  .info-area.info-horizontal .icon i,
  .card-pricing.card-plain ul li {
    color: $white-color;
  }
  .description,
  .info-area .icon:not(.icon-circle) {
    color: $opacity-8;
  }
  .card:not(.card-plain) {
    .info-title {
      color: $black-color;
    }
    .info-area p,
    .info-area .icon,
    .description {
      color: $dark-gray;
    }
  }

  .footer {
    color: $white-color;
  }
  .card-plain {
    [class*='text-'],
    ul li b {
      color: $white-color;
    }
    .category {
      color: rgba($white-color, 0.5);
    }
  }

  &:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: '';
    background-color: rgba(0, 0, 0, 0.7);
  }

  .container {
    z-index: 2;
    position: relative;
  }
}
