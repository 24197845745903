.fade {
	&:not(.show) {
		opacity: 0;
	}
}

.collapsing {
	&:not(.show) {
		height: 0px;
		opacity: 0;
		animation: close 0.5s ease;
	}
}

.collapsing {
	overflow: hidden;
	animation: open 0.5s ease;
}

@keyframes open {
	0% {
		height: 0px;
		opacity: 0;
	}

	100% {
		height: 190px;
		opacity: 1;
	}
}

@keyframes close {
	0% {
		height: 190px;
		opacity: 1;
	}
	100% {
		height: 0px;
		opacity: 0;
	}
}
